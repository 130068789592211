@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, .App, .Chat {
	height: 100%;
	min-height: 100%;
	overflow: hidden;
}

.content a {
	color: revert;
	text-decoration: revert;
}